<template>
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end mb-h" v-if="isNotWizard">
      <div class="col-lg-6 p-0">
        <div class="float-right">
          <WButton info
            v-if="!isDisable"
            @click="openGuestControlWizard"
          >
            {{ $t('GuestControl.addRule') }}
          </WButton>
        </div>
      </div>
    </div>

    <div class="col-lg-12" v-if="isNotWizard">
      <section class="redirects" :class="{ 'edit-visible': editVisible }">
        <section class="redirects-list animated fadeIn">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div v-if="isRedirectsLoading"><Loader-spinner></Loader-spinner></div>
                <div class="card-header">
                  <div>
                    <i class="fa fa-align-justify"></i>
                    {{ $t('GuestControl.tableHeading') }}
                  </div>
                </div>
                <div class="card-block">
                  <RedirectsRulesTable
                    :items="redirectsList"
                    :activeRowRedirectId="editRedirectId"
                    :searchQuery="filters.query"
                    :isDataLoading="false"
                    :isDisabled="false"
                    :isOneColMode="Boolean(editVisible)"
                    @on-row-click="openEditPanel"
                  >
                    <template v-slot:table-menu>
                      <div class="control-panel">
                        <div>
                          <WInput md
                            customClass="guest-panel-input"
                            id="search"
                            :placeholder="$t('general.search')"
                            name="search"
                            v-model.trim="filters.query"
                            :disabled="false"
                            data-vv-validate-on="input"
                            :data-vv-as="$t('general.search')"
                          >
                            <template v-slot:icon-right>
                              <button type="button"
                                v-show="filters.query"
                                class="clear-icon-button"
                                @click="filters.query = ''"
                              >
                                <i class="fa fa-remove"></i>
                              </button>
                            </template>
                          </WInput>
                        </div>

                        <!-- todo refactor -->
                        <div class="col-lg-3  p-0 " v-if="areLocationsVisible">
                          <div class="input-group-for-location-select-in-redirects--filters">
                            <SelectComponentV3ForUseInLocationSelectors
                              :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                                v-if="showLocationFilter"
                                no-wrap-selected-option
                                class="select-location-in-redirects--filters"
                                enable-max-option-width
                                :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                                :show-delete-button="!isSelectedDefaultLocationForRequests || locationInFiltersData"
                                v-model="locationInFiltersData"
                                track-by="name"
                                option-id-name="id"
                                :no-options-found="$t('general.noResultForSearch')"
                                :async-function="requestLocations.bind(this)"
                                :placeholder="$t('portalStats.selectLocation')"
                                @select="selectLocationInFilters"
                                >
                              <template #dropdown-start>
                                <div class="ml-h mt-1">
                                  <Switch-component
                                    v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                                    @input="refreshRedirectsList"
                                    :label="$t('general.withChild')"
                                    v-model="filters.with_childs"
                                    class=""
                                  />
                                </div>
                              </template>
                            </SelectComponentV3ForUseInLocationSelectors>
                          </div>
                        </div>
                      </div>
                    </template>
                </RedirectsRulesTable>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="redirect-edit" v-if="editVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info">
                          <div class="edit-header__name">
                            <span class="h4">{{ redirectEditCurrent.name }}</span>
                          </div>
                          <div class="edit-header__tags"></div>
                          <WButton secondary outline sm
                            customClass="edit-btn-close new-modern-style-btn-close-panel"
                            @click="disableEditMode"
                          >
                            <i class="fa fa-close"></i>
                          </WButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block pt-0">
                  <small class="text-muted" v-if="!isDisable">{{ $t('general.editingMode') }}</small>
                  <small class="text-muted" v-if="isDisable">{{ $t('general.readOnlyMode') }}</small>

                  <GeneralSettings
                    :redirectRuleData="updatedRedirect"
                    :isDisable="isDisable"
                    :key="updatedRedirect.id"
                    class="mb-1"
                  ></GeneralSettings>

                  <!--<a href="#" @click.prevent="addProfiles()" v-if="!updatedRedirect.default_profile" class="text-success">-->
                  <!--+add clients profiles-->
                  <!--</a>-->

                  <!--<div class="settings-block mb-1" v-if="updatedRedirect.default_profile">-->
                  <!--<a href="#" @click.prevent="deleteProfiles()" class="text-danger pull-right">-->
                  <!--delete clients profiles-->
                  <!--</a>-->
                  <!--<DefaultProfile-->
                  <!--:redirectRuleData="updatedRedirect"-->
                  <!--:defaultProfile="updatedRedirect.user_profiles[updatedRedirect.default_profile]"-->
                  <!--:isWizard="false"-->
                  <!--:isDisable="isDisable"-->
                  <!--class="mb-3"-->
                  <!--&gt;-->
                  <!--</DefaultProfile>-->

                  <!--<CustomProfiles-->
                  <!--:redirectRuleData="updatedRedirect"-->
                  <!--:isWizard="false"-->
                  <!--:isDisable="isDisable"-->
                  <!--&gt;-->
                  <!--</CustomProfiles>-->
                  <!--</div>-->
                </div>

                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline
                      customClass="mr-1"
                      v-if="!isDisable"
                      @click="updateRedirect"
                      :disabled="errors.any()"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.save') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                    <WButton info outline
                      v-if="!isDisable"
                      @click="copyRedirect"
                      :disabled="errors.any()"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.copy') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                  </div>
                  <div>
                    <WButton danger outline
                      :customClass="{ 'mr-1': true, invisible: muteEdit }"
                      v-if="!isDisable"
                      @click="openDeletingDialog(updatedRedirect.id)"
                    >
                      <span :class="{ invisible: muteEdit }">{{ $t('general.delete') }}</span>
                      <span v-if="muteEdit" class="loader loader--mini"></span>
                    </WButton>
                    <WButton secondary outline
                      :customClass="{ invisible: muteEdit }"
                      @click="disableEditMode"
                    >
                      <span v-if="isDisable">{{ $t('general.close') }}</span>
                      <span v-else>{{ $t('general.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
    <div class="col-lg-12"><router-view></router-view></div>

    <DeleteRedirectRuleModal
      :isOpen="deletingRedirectModal"
      :redirectName="redirectName(redirectIdToDelete)"
      @close="cancelDeleting"
      @confirm="deleteRedirect"
    />
    </div>
</template>
<script>
import Vue from 'vue';
import { API_URL } from '@/config';
import redirectsService from '../../services/redirectsService';
import redirectPollingService from '../../services/redirectPollingService';
import commonService from '../../services/commonService';
import Modal from '../../components/Modal.vue';
import GeneralSettings from '../../components/GuestControl/generalSettings.vue';
import helpers from '../../helpers';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import locationService from '../../services/locationService';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

import {
  DeleteRedirectRuleModal,
  RedirectsRulesTable
} from './components';

export default {
  name: 'GuestControl',
  components: {
    Modal,
    GeneralSettings,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors,
    DeleteRedirectRuleModal,
    RedirectsRulesTable,
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      updatedRedirect: {},
      newMacAddress: '',
      newUrlDomain: '',
      newUrlIp: '',
      redirectIdToDelete: '',
      deletingRedirectModal: false,
      isTipsToFillShow: false,
      filters: {
        location: 'All locations',
        with_childs: false,
        query: ''
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    editVisible() {
      return this.$store.state.editRedirectId;
    },
    isNotWizard() {
      return this.$route.name === 'GuestControl';
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    muteEdit() {
      return this.$store.state.muteRedirectEdit;
    },
    redirectEditCurrent() {
      const { redirectsList } = this.$store.state;
      let currentRedirect;
      redirectsList.forEach((redirect) => {
        if (redirect.id == this.$store.state.editRedirectId) {
          currentRedirect = JSON.parse(JSON.stringify(redirect));
        }
      });
      // if(currentRedirect.redirect_url.indexOf('http://') !== -1) {
      //     let redirectUrlWOHttp = currentRedirect.redirect_url.split('http://');
      //     currentRedirect.redirect_url = redirectUrlWOHttp[1];
      // }
      // currentRedirect.url_list.forEach((url, index) => {
      //   if (url.domain_name === this.showOnlyDomain(currentRedirect.redirect_url)) {
      //     currentRedirect.redirect_ip = url.ip;
      //     currentRedirect.url_list.splice(index, 1);
      //   }
      // });
      this.updatedRedirect = JSON.parse(JSON.stringify(currentRedirect));
      return currentRedirect;
    },
    redirectsList() {
      const list = this.$store.state.redirectsList;

      if (!list) {
        return [];
      }

      return list;
    },
    editRedirectId() {
      return this.$store.state.editRedirectId;
    },
    isRedirectsLoading() {
      return this.$store.state.loadingRedirects;
    }
  },
  watch: {
    redirectsList() {
      redirectPollingService.startPolling(this);
    }
  },
  methods: {
    updateRedirect() {
      if (this.newMacAddress) {
        this.updatedRedirect.mac_list.push(this.newMacAddress);
      }
      if (this.newUrlIp) {
        this.updatedRedirect.url_list.push({
          ip: this.newUrlIp,
          domain_name: this.newUrlDomain
        });
      }
      this.clearFields();
      redirectsService.updateRedirect(this);
    },
    copyRedirect() {
      const copy = { ...this.updatedRedirect, name: `${this.updatedRedirect.name}-new` };

      this.axios.post(`${API_URL || ''}/api/network/redirects`, { action: 'C', items: { 0: copy } }).then(
        (response) => {
          if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
            this.$store.commit('toggleLoadingFirewalls', true);
          }
          redirectPollingService.hadleOperationResponse(response.data.data.items);
          // redirectsService.getRedirectsRule(this);
           redirectsService.getRedirectsRule(this, true);
        },
        (err) => {
          VueNotifications.error({ message: err });
          this.error = err;
          this.$store.commit('toggleLoadingFirewalls', true);
        }
      );
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshRedirectsList()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
       // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    clearFields() {
      this.newMacAddress = '';
      this.newUrlIp = '';
      this.newUrlDomain = '';
    },
    deleteRedirect() {
      redirectsService.deleteRedirect(this);
    },
    refreshRedirectsList() {
      // redirectsService.getRedirectsRule(this);
      redirectsService.getRedirectsRule(this, true);
    },
    openDeletingDialog(id) {
      this.redirectIdToDelete = id;
      this.deletingRedirectModal = true;
    },
    cancelDeleting() {
      this.deletingRedirectModal = false;
      this.redirectIdToDelete = '';
    },
    redirectName(id) {
      const redirectName = commonService.redirectNamebyId(id);

      if (!redirectName) {
        return '';
      }

      return redirectName;
    },
    openEditPanel(redirectId) {
      this.$store.commit('setRedirectEditId', redirectId);
    },
    openGuestControlWizard() {
      this.$router.push({ name: 'GuestControl-wizard' });
      this.disableEditMode();
      this.$store.commit('clearNewRedirectData');
    },
    addProfiles() {
      this.updatedRedirect.default_profile = 'default profile';
      this.updatedRedirect.user_profiles[this.updatedRedirect.default_profile] = {
        name: 'default profile',
        qos_in: [
          {
            block: false,
            filters: [],
            rate: 0,
            rate_type: 'Kbit'
          }
        ],
        qos_out: [
          {
            block: false,
            filters: [],
            rate: 0,
            rate_type: 'Kbit'
          }
        ]
      };
    },
    deleteProfiles() {
      this.updatedRedirect.user_profiles = {};
      this.updatedRedirect.default_profile = '';
      this.errors.remove('profile-name', 'edit-profile-name');
    },
    disableEditMode() {
      this.$store.commit('setRedirectEditId', null);
      this.redirectIdToDelete = '';
    }
  },
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    // redirectsService.getRedirectsRule(this);
    redirectsService.getRedirectsRule(this, true);
    this.disableEditMode();
    this.clearFields();
  }
};
</script>

<style lang="scss">
            .input-group-for-location-select-in-redirects--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-redirects--filters .select__list-container {
    width: 400px;
  }

  .select-location-in-redirects--filters {
    width: 100%;
  }

.select-location-in-redirects--filters .select__control {
  border: 1px solid var(--border-color);
  border-radius: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-height: auto;
}

.select-location-in-redirects--filters .select__input {
  margin: 0;
}

.select-location-in-redirects--filters .select__tag {
  margin: 0;
  padding: 0;
}

.redirects {
  display: flex;
}

.redirects-list,
.redirect-edit {
  transition: width 0.2s;
}

.redirects-list {
  width: 100%;
}

.redirect-edit {
  display: inline-block;
  width: 0%;
}

.redirect-edit .card-header {
  border: 0;
}

.edit-visible .redirects-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 35%;
}

.edit-visible .redirect-edit {
  transition: width 0.2s;
  width: 65%;
  min-width: 500px;
}

.url-input-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  padding-right: 15px;
  padding-left: 15px;

  & .form-group {
    flex-grow: 1;
  }
}

.redirect-loading {
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.redirect-url-input {
  position: relative;
  /*&::before{*/
  /*position: absolute;*/
  /*content: 'http://';*/
  /*left: 6px;*/
  /*top: 6px;*/
  /*color: #BCC5D0 ;*/
  /*}*/

  /*& input{*/
  /*padding-left: 50px;*/
  /*}*/
}

.how-to-fill {
  word-break: break-word;
}

.edit-btn-close {
  line-height: 14px;
  font-size: 14px !important;
  height: 27.5px;
  width: 29px;
}
</style>

<stype lang="css" scoped>
.control-panel {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
}

.control-panel > div {
  flex: 0 0 20%;
}

</stype>

<style lang="css">
/*
  кастомные стили для WInput
  todo после того как все инпуты в проекте изменяться на WInput, отрефакторить и удалить
*/
.guest-panel-input {
  border-radius: 8px !important;
}

.clear-icon-button {
  border: none;
  padding: 0;
  background: transparent;
  color: inherit;
  transition: var(--transition);
}

.clear-icon-button:hover {
  color: var(--brand-info);
}
</style>
