/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы RedirectsRulesTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name,
      visibilityDropdownSettings: {
        hidden: false
      }
    },
    {
      label: $t('GuestControl.redirectType'),
      field: 'redirectType',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.redirectType,
      visibilityDropdownSettings: {
        hidden: false
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ redirectsData }) {
  return redirectsData.map(redirect => {
    const {
      id,
      base_location,
      mac_list,
      name,
      no_masquerade,
      preauth_list,
      redirect_url,
      tags,
      url_list,
      redirect_type
    } = redirect;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: redirect,
      name,
      // todo не реализовано на бэке пока
      redirectType: redirect_type //  static dynamic
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
